import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RoutineBuilder } from '../../models/builder/routine.builder';
import { Routine } from '../../models/routine';

@Injectable()
export class RoutineApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}routines`;
  }

  getRoutines(): Observable<Routine[]> {
    return this.httpClient.get(`${this.apiUrl}`)
      .pipe(
        map(RoutineBuilder.fromList)
      );
  }
}
