import { Prescription } from './prescription';

export class RiskPrescription {
  constructor(
    public hash?: string,
    public prescriptionHash?: string,
    public answer1?: string,
    public answer2?: string,
    public answer3?: string,
    public answer4?: string,
    public answer5?: string,
    public prescription?: Prescription
  ) { }

  clone() {
    return new RiskPrescription(
      this.hash,
      this.prescriptionHash,
      this.answer1,
      this.answer2,
      this.answer3,
      this.answer4,
      this.answer5,
      this.prescription
    );
  }
}
