import { mapCollection, sortByNumber } from '../../utils/collection.utils';
import { parseNumber } from '../../utils/number.utils';
import { RoutineLevel } from '../routine-level';

export class RoutineLevelBuilder {
  static fromJson(json: any): RoutineLevel {
    return json
      ? new RoutineLevel(
        json.hash,
        parseNumber(json.level),
        json.pdf,
      )
      : null;
  }

  static fromList(list: any): RoutineLevel[] {
    return Array.isArray(list)
      ? sortByNumber('level', mapCollection(RoutineLevelBuilder.fromJson, list))
      : [];
  }
}
