import { RoutineLevel } from './routine-level';

export class Routine{
  constructor(
    public hash?: string,
    public name?: string,
    public routineLevels?: RoutineLevel[],
  ) { }

  clone() {
    return new Routine(
      this.hash,
      this.name,
      this.routineLevels,
    );
  }
}
