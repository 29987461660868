export class Appointment {
  constructor(
    public hash?: string,
    public name?: string,
    public surnames?: string,
    public phoneNumber?: string,
    public appointmentDate?: Date,
    public appointmentDateEnd?: Date,
    public appointmentTime?: String
  ) { }

  clone(): Appointment {
    return new Appointment(
      this.hash,
      this.name,
      this.surnames,
      this.phoneNumber,
      this.appointmentDate,
      this.appointmentDateEnd,
      this.appointmentTime
    );
  }
}
