import { mapCollection, sortByString } from '../../utils/collection.utils';
import { Pathology } from '../pathology';

export class PathologyBuilder {
  static fromJson(json: any): Pathology {
    return json
      ? new Pathology(
        json.hash,
        json.name
      )
      : null;
  }

  static fromList(list: any): Pathology[] {
    return Array.isArray(list)
      ? sortByString('name', mapCollection(PathologyBuilder.fromJson, list))
      : [];
  }
}
