import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { STEP_ROUTE_MATCH } from '../../config/routes.config';

@Injectable()
export class NavigationService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) { }

  public startSaveHistory(): void{
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.history.push(event.urlAfterRedirects);
        }
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  get hasHistoryRegistered() {
    return this.history.length > 0;
  }

  public goBack(): void {

    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  public goFwd(): void {
    if (this.history.length > 0) {
      this.location.forward();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  public getPreviousUrl(): string {
    if (this.history.length > 0) {
        return this.history[this.history.length - 2];
    }

    return '';
  }

  public getPrescriptionStepRouteByStep(step): string {
    try {
      if (!step || step < 0) {
        return STEP_ROUTE_MATCH[0].route;
      }
      const matchedRoute = STEP_ROUTE_MATCH.find(item => item.stepNumber === step);
      if (matchedRoute) {
        return matchedRoute.route;
      }
      // health default
      return STEP_ROUTE_MATCH[1].route;
    } catch (error) {
      return STEP_ROUTE_MATCH[1].route;
    }
  }
}
