import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PathologyBuilder } from '../../models/builder/pathology.builder';

@Injectable()
export class PathologyApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}pathologies`;
  }

  getPathologies() {
    return this.httpClient.get(`${this.apiUrl}`).pipe(map(PathologyBuilder.fromList));
  }
}
