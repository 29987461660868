import { mapCollection } from '../utils/collection.utils';
import { ClientPlan } from './client-plan';
import { Origin } from './origin';

export class Client {
  constructor(
    public hash?: string,
    public cif?: string,
    public member?: boolean,
    public memberType?: string,
    public name?: string,
    public firstSurname?: string,
    public email?: string,
    public telephoneNumber?: string,
    public borndate?: Date,
    public sex?: string,
    public lastPrescriptionStep?: number,
    public clientPlans?: ClientPlan[],
    public origin?: Origin,
    public city?: string,
    public profileImage?: string,
    public contact?: number,
    public hearService?: string,
    public hearServiceOthers?: string,
  ) { }

  clone(): Client {
    return new Client(
      this.hash,
      this.cif,
      this.member,
      this.memberType,
      this.name,
      this.firstSurname,
      this.email,
      this.telephoneNumber,
      this.borndate,
      this.sex,
      this.lastPrescriptionStep,
      mapCollection((clientPlan: ClientPlan) => clientPlan?.clone, this.clientPlans),
      this.origin,
      this.city,
      this.profileImage,
      this.contact,
      this.hearService,
      this.hearServiceOthers
    );
  }
}
