import { parseBoolean } from '../../utils/boolean.utils';
import { mapCollection } from '../../utils/collection.utils';
import { parseNumber } from '../../utils/number.utils';
import { ClientPlanRoutineLevel } from '../client-plan-routine-level';

export class ClientPlanRoutineLevelBuilder {
  static fromJson(json: any): ClientPlanRoutineLevel {
    return json
      ? new ClientPlanRoutineLevel(
        json.hash,
        json.routineName,
        parseBoolean(json.isDefaultRoutine),
        parseNumber(json.routineLevel),
        parseNumber(json.routineDate),
        parseNumber(json.routineDay),
        json.dayOfWeek,
      )
      : null;
  }

  static fromList(list: any): ClientPlanRoutineLevel[] {
    return Array.isArray(list)
      ? mapCollection(ClientPlanRoutineLevelBuilder.fromJson, list)
      : [];
  }

  static fromMatrix(list: any): ClientPlanRoutineLevel[][] {
    return Array.isArray(list)
      ? mapCollection(ClientPlanRoutineLevelBuilder.fromList, list)
      : [];
  }
}
