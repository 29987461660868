import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TestGroupBuilder } from '../../models/builder/test-group.builder';
import { TestBuilder } from '../../models/builder/test.builder';

@Injectable()
export class TestApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}tests`;
  }

  getTests() {
    return this.httpClient.get(`${this.apiUrl}`).pipe(map(TestBuilder.fromList));
  }

  getTestGroups() {
    return this.httpClient.get(`${this.apiUrl}/groups`).pipe(map(TestGroupBuilder.fromList));
  }
}
