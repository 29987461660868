import { mapCollection, sortByString } from '../../utils/collection.utils';
import { Prescription } from '../prescription';

export class PrescriptionBuilder {
  static fromJson(json: any): Prescription {
    return json
      ? new Prescription(
        json.hash,
        json.step,
        json.endDate ? new Date(json.endDate) : null,
        json.createdAt ? new Date(json.createdAt) : null
      )
      : null;
  }

  static fromList(list: any): Prescription[] {
    return Array.isArray(list)
      ? mapCollection(PrescriptionBuilder.fromJson, list)
      : [];
  }
}
