import { mapCollection, sortByString } from '../../utils/collection.utils';
import { TestGroup } from '../test-group';

export class TestGroupBuilder {
  static fromJson(json: any): TestGroup {
    return json
      ? new TestGroup(
        json.hash,
        json.name,
        json.img,
      )
      : null;
  }

  static fromList(list: any): TestGroup[] {
    return Array.isArray(list)
      ? mapCollection(TestGroupBuilder.fromJson, list)
      : [];
  }
}
