import { mapCollection } from '../../utils/collection.utils';
import { ActivityPrescription } from '../activity-prescription';

export class ActivityPrescriptionBuilder {
  static fromJson(json: any): ActivityPrescription {
    return json
      ? new ActivityPrescription(
        json.hash,
        json.prescriptionHash,
        json.intenseActivityDays,
        json.intenseActivityTime,
        json.moderateActivityDays,
        json.moderateActivityTime,
        json.walkingDays,
        json.walkingTime,
        json.sittingTime,
        json.mets,
        json.prescription
      )
      : null;
  }

  static fromList(list: any): ActivityPrescription[] {
    return Array.isArray(list)
      ? mapCollection(ActivityPrescriptionBuilder.fromJson, list)
      : [];
  }
}
