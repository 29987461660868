import { Component } from '@angular/core';
import { ToasterService } from '../../core/services/visual/toaster.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent {
  done = false;

  constructor(
    private authService: AuthService,
    private toasterService: ToasterService
  ) { }

  async onRecover(email: string) {
    try {
      await this.authService.recoverPassword(email);
      this.done = true;
    } catch (error) {
      await this.authService.recoverPassword(email);
      this.done = true;
      // this.toasterService.showErrorTranslating('auth.recoverPassword.error');
    }
  }

}
