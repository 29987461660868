import { mapCollection, sortByNumber } from '../../utils/collection.utils';
import { Routine } from '../routine';
import { RoutineLevelBuilder } from './routine-level.builder';

export class RoutineBuilder {
  static fromJson(json: any): Routine {
    return json
      ? new Routine(
        json.hash,
        json.name,
        RoutineLevelBuilder.fromList(json.routineLevels),
      )
      : null;
  }

  static fromList(list: any): Routine[] {
    return Array.isArray(list)
      ? sortByNumber('name', mapCollection(RoutineBuilder.fromJson, list))
      : [];
  }
}
