import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { PRESCRIPTION_STEP_RECOMMENDATION, PRESCRIPTION_STEP_RESUME } from 'src/app/core/config/constants';
import { PrescriptionService } from '../../dashboard/prescription/prescription.service';

const STEP_ROUTE_MATCH = [
  { route: 'identification', stepNumber: 1 },
  { route: 'health', stepNumber: 2 },
  { route: 'risk', stepNumber: 3 },
  { route: 'activity', stepNumber: 4 },
  { route: 'change', stepNumber: 5 },
  { route: 'approach', stepNumber: 6 },
  { route: 'resume', stepNumber: 7 },
  { route: 'recommendation', stepNumber: 8 }
];


@Directive({
  selector: '[appFillStepper]'
})
export class FillStepperDirective implements OnInit {
  @Input() stepNumber: number;
  @Input() currentRoute: string;
  prescriptionLastStepNumber: number;

  constructor(private el: ElementRef, private renderer: Renderer2, private prescriptionService: PrescriptionService) { }

  ngOnInit(): void {
    this.prescriptionService.prescriptionLastStepNumber.subscribe(prescriptionLastStepNumber => {
      if (prescriptionLastStepNumber === PRESCRIPTION_STEP_RESUME) {
        this.prescriptionLastStepNumber = prescriptionLastStepNumber + 1;
      } else {
        this.prescriptionLastStepNumber = prescriptionLastStepNumber;
      }
    });
    this.fillStep(this.stepNumber, this.currentRoute, this.prescriptionLastStepNumber);
  }

  fillStep(stepNumber: number, currentRoute: string, prescriptionLastStepNumber: number): void {

    const currentRouteNumber = STEP_ROUTE_MATCH.find(item => item.route === currentRoute).stepNumber;

    if (currentRouteNumber === stepNumber) {
      this.renderer.addClass(this.el.nativeElement, 'circle--half');
    }

    if ( prescriptionLastStepNumber >= stepNumber && currentRouteNumber !== stepNumber ) {
      this.renderer.addClass(this.el.nativeElement, 'circle--full');
    }
  }

}
