import { Prescription } from './prescription';

export class ActivityPrescription {
  constructor(
    public hash?: string,
    public prescriptionHash?: string,
    public intenseActivityDays?: number,
    public intenseActivityTime?: number,
    public moderateActivityDays?: number,
    public moderateActivityTime?: number,
    public walkingDays?: number,
    public walkingTime?: number,
    public sittingTime?: number,
    public mets?: number,
    public prescription?: Prescription
  ) { }

  clone() {
    return new ActivityPrescription(
      this.hash,
      this.prescriptionHash,
      this.intenseActivityDays,
      this.intenseActivityTime,
      this.moderateActivityDays,
      this.moderateActivityTime,
      this.walkingDays,
      this.walkingTime,
      this.sittingTime,
      this.mets,
      this.prescription
    );
  }
}
