import { mapCollection, sortByString } from '../../utils/collection.utils';
import { Test } from '../test';
import { TestGroup } from '../test-group';

export class TestBuilder {
  static fromJson(json: any): Test {
    return json
      ? new Test(
        json.hash,
        json.name,
        json.title,
        json.videoUrl,
        json.duration,
        json.repetitions,
        json.description,
        json.testGroup
      )
      : null;
  }

  static fromList(list: any): Test[] {
    return Array.isArray(list)
      ? mapCollection(TestBuilder.fromJson, list)
      : [];
  }
}
