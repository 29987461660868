import { mapCollection } from '../../utils/collection.utils';
import { RiskPrescription } from '../risk-prescription';

export class RiskPrescriptionBuilder {
  static fromJson(json: any): RiskPrescription {
    return json
      ? new RiskPrescription(
        json.hash,
        json.prescriptionHash,
        json.answer1,
        json.answer2,
        json.answer3,
        json.answer4,
        json.answer5,
        json.prescription
      )
      : null;
  }

  static fromList(list: any): RiskPrescription[] {
    return Array.isArray(list)
      ? mapCollection(RiskPrescriptionBuilder.fromJson, list)
      : [];
  }
}
