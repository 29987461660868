export class Benefit{
  constructor(
    public hash?: string,
    public name?: string,
  ) { }

  clone() {
    return new Benefit(
      this.hash,
      this.name
    );
  }
}
