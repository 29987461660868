import { mapCollection } from '../../utils/collection.utils';
import { ChangePrescription } from '../change-prescription';
import { Prescription } from '../prescription';

export class ChangePrescriptionBuilder {
  static fromJson(json: any): ChangePrescription {
    return json
      ? new ChangePrescription(
        json.hash,
        json.prescriptionHash,
        json.answer1,
        json.answer2,
        json.answer3,
        json.answer4,
        json.result,
        json.prescription
      )
      : null;
  }

  static fromList(list: any): ChangePrescription[] {
    return Array.isArray(list)
      ? mapCollection(ChangePrescriptionBuilder.fromJson, list)
      : [];
  }
}
