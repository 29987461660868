import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { Subscription } from 'rxjs';
import { OptionInterface } from 'src/app/core/models/option.interface';
import { Routine } from 'src/app/core/models/routine';
import { find } from 'src/app/core/utils/collection.utils';
import { isNullOrUndefined } from 'src/app/core/utils/object.utils';
import { buildOptions, buildOptionsAndEmpty } from 'src/app/core/utils/option.utils';

@Component({
  selector: 'app-add-activity-modal',
  templateUrl: './add-activity-modal.component.html',
  styleUrls: ['./add-activity-modal.component.css']
})
export class AddActivityModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string;
  @Input() routinesKey: string;
  @Input() levelsKey: string;
  @Input() routines: Routine[];
  @Input() confirmLabel = 'shared.confirm.confirm';
  @Input() cancelLabel = 'shared.confirm.cancel';
  @Input() hideHeader = false;
  @Output() confirm = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();
  @ViewChild(NgxSmartModalComponent) modal: NgxSmartModalComponent;
  form: FormGroup;
  levelsOptions: OptionInterface[];
  routinesOptions: OptionInterface[];
  routineSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      routineHash: ['', [Validators.required]],
      routineLevelHash: ['', [Validators.required]],
    });

    this.routineSubscription = this.form.get('routineHash').valueChanges.subscribe(this.setRoutineLevels);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.routines && changes['routines']) {
      this.routinesOptions = buildOptions(this.routines, 'hash', 'name');
    }
  }

  ngOnDestroy(): void {
    this.routineSubscription.unsubscribe();
  }

  open() {
    if (this.form) {
      this.form.get('routineHash').setValue('');
      this.form.get('routineLevelHash').setValue('');
      this.levelsOptions = null;
    }
    this.modal.open();
  }

  close() {
    this.closeModal();
    this.onClose();
  }

  onClose() {
    this.cancel.emit();
    this.form.markAsUntouched();
  }

  onCancel() {
    this.closeModal();
    this.onClose();
  }

  onConfirm() {
    this.closeModal();
    this.confirm.emit(this.form.get('routineLevelHash').value);
  }

  private closeModal() {
    this.modal.close();
  }

  private setRoutineLevels = (routineHash: string) => {
    const selectedRoutine: Routine = find((routine: Routine) => routine.hash === routineHash, this.routines);
    if (isNullOrUndefined(selectedRoutine)) {
      return;
    }

    this.form.get('routineLevelHash').setValue('');
    this.levelsOptions = buildOptionsAndEmpty(selectedRoutine.routineLevels, 'hash', 'level');
  };
}
