import { mapCollection } from '../../utils/collection.utils';
import { PhysicalProfilePrescription } from '../physical-profile-prescription';

export class PhysicalProfilePrescriptionBuilder {
  static fromJson(json: any): PhysicalProfilePrescription {
    return json
      ? new PhysicalProfilePrescription(
        json.hash,
        json.prescriptionHash,
        json.weight,
        json.height,
        json.bodyFat,
        json.visceralFat,
        json.muscleMass,
        json.metabolicAge,
        json.prescription
      )
      : null;
  }

  static fromList(list: any): PhysicalProfilePrescription[] {
    return Array.isArray(list)
      ? mapCollection(PhysicalProfilePrescriptionBuilder.fromJson, list)
      : [];
  }
}
