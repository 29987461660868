export class RoutineLevel{
  constructor(
    public hash?: string,
    public level?: number,
    public pdf?: string,
  ) { }

  clone() {
    return new RoutineLevel(
      this.hash,
      this.level,
      this.pdf,
    );
  }
}
