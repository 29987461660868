import { mapCollection } from '../utils/collection.utils';
import { ClientPlanRoutineLevel } from './client-plan-routine-level';

export class ClientPlan {
  constructor(
    public hash?: string,
    public startDate?: number,
    public endDate?: number,
    public clientPlanRoutineLevels?: ClientPlanRoutineLevel[][],
  ) { }

  clone() {
    return new ClientPlan(
      this.hash,
      this.startDate,
      this.endDate,
      mapCollection((clientPlanRoutineLevels: ClientPlanRoutineLevel[]) =>
        mapCollection((clientPlanRoutineLevel: ClientPlanRoutineLevel) =>
          clientPlanRoutineLevel?.clone, clientPlanRoutineLevels), this.clientPlanRoutineLevels)
    );
  }
}
