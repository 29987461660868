export class ClientPlanRoutineLevel {
  constructor(
    public hash?: string,
    public routineName?: string,
    public isDefaultRoutine?: boolean,
    public routineLevel?: number,
    public routineDate?: number,
    public routineDay?: number,
    public dayOfWeek?: string,
  ) { }

  clone() {
    return new ClientPlanRoutineLevel(
      this.hash,
      this.routineName,
      this.isDefaultRoutine,
      this.routineLevel,
      this.routineDate,
      this.routineDay,
      this.dayOfWeek,
    );
  }
}
