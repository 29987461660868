export class TestGroup {
  constructor(
    public hash?: string,
    public name?: string,
    public img?: string
  ) { }

  clone() {
    return new TestGroup(
      this.hash,
      this.name,
      this.img
    );
  }
}
