import { mapCollection, sortByString } from '../../utils/collection.utils';
import { Benefit } from '../benefit';

export class BenefitBuilder {
  static fromJson(json: any): Benefit {
    return json
      ? new Benefit(
        json.hash,
        json.name
      )
      : null;
  }

  static fromList(list: any): Benefit[] {
    return Array.isArray(list)
      ? sortByString('name', mapCollection(BenefitBuilder.fromJson, list))
      : [];
  }
}
