import { mapCollection, sortByNumber, sortByString } from '../../utils/collection.utils';
import { Appointment } from '../appointment';

export class AppointmentBuilder {
  static fromJson(json: any): Appointment {
    return json
      ? new Appointment(
        json.hash,
        json.name,
        json.surnames,
        json.telephone,
        json.appointmentStart,
        json.appointmentEnd,
        getAppointmentTime(json.appointmentStart)
      )
      : null;
  }

  static fromList(list: any): Appointment[] {
    return Array.isArray(list)
      ? mapCollection(AppointmentBuilder.fromJson, list)
      : [];
  }
}

function getAppointmentTime(timeStart) {
  let hours = parseInt(timeStart.substring(11, 13));
  let minutes = timeStart.substring(14, 16);
  let { gap, sign } = getTimezoneOffset(new Date(timeStart));
  
  if(sign === '+') hours = hours + gap;
  else hours = hours - gap;
  
  return hours + ':' + minutes;
}

function getTimezoneOffset(date) {
  const now = new Date(date);
  const gap = Math.floor(Math.abs(now.getTimezoneOffset()) / 60);
  const sign = now.getTimezoneOffset() > 0 ? "-" : "+";

  return {gap, sign};
}