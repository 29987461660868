import { STEP_ROUTE_MATCH } from '../../core/config/routes.config';

export class Prescription {
  constructor(
    public hash?: string,
    public step?: number,
    public endDate?: Date,
    public createdAt?: Date,
  ) { }

  clone() {
    return new Prescription(
      this.hash,
      this.step,
      this.endDate,
      this.createdAt
    );
  }

  get percentage() {
    try {
      const steps = STEP_ROUTE_MATCH.length;
      const eachStepStatus = 100 / steps;
      const prescriptionStatus = this.step;
      let calculatedPercentage = 100;
      if (prescriptionStatus !== steps || eachStepStatus < 100) {
        calculatedPercentage = prescriptionStatus * eachStepStatus;
      }

      return calculatedPercentage;
    } catch (error) {
      return 0;
    }
  }
}
