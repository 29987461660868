import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { Activity } from 'src/app/core/models/activity';

@Component({
  selector: 'app-recommendation-activity-modal',
  templateUrl: './recommendation-activity-modal.component.html',
  styleUrls: ['./recommendation-activity-modal.component.css']
})
export class RecommendationActivityModalComponent {
  @Input() activity: Activity;
  @Input() weekdayNames: string;
  @Output() cancel = new EventEmitter<void>();
  @ViewChild(NgxSmartModalComponent) modal: NgxSmartModalComponent;

  open() {
    this.modal.open();
  }

  close() {
    this.modal.close();
  }

  onCancel() {
    this.close();
    this.cancel.emit();
  }
}
