import { TestGroup } from './test-group';
export class Test {
  constructor(
    public hash?: string,
    public name?: string,
    public title?: string,
    public videoUrl?: string,
    public duration?: number,
    public repetitions?: number,
    public description?: string,
    public testGroup?: TestGroup
  ) { }

  clone() {
    return new Test(
      this.hash,
      this.name,
      this.title,
      this.videoUrl,
      this.duration,
      this.repetitions,
      this.description,
      this.testGroup
    );
  }
}
