import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { OriginBuilder } from '../../models/builder/origin.builder';
import { Origin } from '../../models/origin';

@Injectable()
export class OriginApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}origins`;
  }

  getOrigins(): Observable<Origin[]> {
    return this.httpClient.get(`${this.apiUrl}`)
      .pipe(
        map(OriginBuilder.fromList)
      );
  }
}
