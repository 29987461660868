import { Directive, Input, HostListener, ElementRef, Renderer2, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrescriptionService } from 'src/app/dashboard/prescription/prescription.service';

const STEP_ROUTE_MATCH = [
  { route: ['identification'], stepNumber: 1 },
  { route: ['health'], stepNumber: 2 },
  { route: ['risk', 'step', '1'], stepNumber: 3 },
  { route: ['activity'], stepNumber: 4 },
  { route: ['change', 'step', '1'], stepNumber: 5 },
  { route: ['approach'], stepNumber: 6 },
  { route: ['resume'], stepNumber: 7 },
  { route: ['recommendation'], stepNumber: 8 }
];

const RISK_PATH_LEVEL = '../../../..';
const DEFAULT_PATH_LEVEL = '../..';

@Directive({
  selector: '[appNavigationStepper]'
})
export class NavigationStepperDirective implements OnInit {
  @Input() stepNumber: number;
  @Input() currentRoute: string;
  isNextStepNavigable: boolean;
  prescriptionLastStepNumber: number;

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    private el: ElementRef,
    private renderer: Renderer2,
    private prescriptionService: PrescriptionService
  ) { }

  ngOnInit() {
    this.prescriptionService.prescriptionLastStepNumber.subscribe(prescriptionLastStepNumber => {
      if (prescriptionLastStepNumber === 6 ){
        this.prescriptionLastStepNumber = prescriptionLastStepNumber + 2;
      } else {
        this.prescriptionLastStepNumber = prescriptionLastStepNumber;
      }
    });

    const currentStepNumber = STEP_ROUTE_MATCH.find(step => step.route[0] === this.currentRoute).stepNumber;
    const nextStepNumber = this.stepNumber;

    const isCurrentStep = nextStepNumber === currentStepNumber;
    this.isNextStepNavigable = nextStepNumber <= this.prescriptionLastStepNumber;

    if (this.isNextStepNavigable) {
      this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer');
    }

    if (!this.isNextStepNavigable) {
      this.renderer.setStyle(this.el.nativeElement, 'cursor', 'not-allowed');
    }

    if (!this.isNextStepNavigable && isCurrentStep) {
      this.renderer.setStyle(this.el.nativeElement, 'cursor', 'default');
    }
  }

  @HostListener('click') onClick() {
    if (this.isNextStepNavigable) {
      const route = STEP_ROUTE_MATCH.find(step => step.stepNumber === this.stepNumber).route;
      const pathLevel = (this.currentRoute === 'risk' || this.currentRoute === 'change') ? RISK_PATH_LEVEL : DEFAULT_PATH_LEVEL;
      this.prescriptionService.updateGoBackStep(this.currentRoute);
      const viewMode = this.activeRoute.snapshot.url[this.activeRoute.snapshot.url.length - 1].path;
      this.route.navigate([pathLevel, ...route, viewMode], { relativeTo: this.activeRoute });
    }
  }

}
