export class Activity{
  constructor(
    public hash?: string,
    public contactEmail?: string,
    public contactPerson?: string,
    public contactTelephone?: string,
    public description?: string,
    public image?: string,
    public links?: string,
    public location?: string,
    public priceNonSubscribers?: string,
    public priceSubscribers?: string,
    public startTimes?: string,
    public title?: string,
    public clubName?: string,
    public weekdayCodes?: string[],
  ) { }
}
