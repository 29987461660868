import { Component, Input } from '@angular/core';
import { ColumnComponent } from '../column/column.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sortable-cell',
  templateUrl: './sortable-cell.component.html',
  styleUrls: ['./sortable-cell.component.css']
})
export class SortableCellComponent {
  @Input() column: ColumnComponent;
  @Input() element: any;
  constructor(private datePipe: DatePipe) {}

  get value() {
    if (this.column.type === 'date') {
      return (this.element
        && this.column && this.column.property) ? this.datePipe.transform(this.element[this.column.property], 'dd/MM/yyyy') : null;
    } else {
      return (this.element && this.column && this.column.property) ? this.element[this.column.property] : null;
    }
  }
}
