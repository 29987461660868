import { Component, Input, OnInit } from '@angular/core';
import { LocalizedPercentPipe } from 'src/app/shared/pipes/localized-percent.pipe';

@Component({
  selector: 'app-circular-progress-bar-cell',
  templateUrl: './circular-progress-bar-cell.component.html',
  styleUrls: ['./circular-progress-bar-cell.component.css']
})
export class CircularProgressBarCellComponent implements OnInit {
  @Input() value: number;

  private progress: number;

  constructor(
    private localizedDecimalPipe: LocalizedPercentPipe,
  ) { }

  ngOnInit() {
    this.progress = this.value / 100;
  }

  formatProgressPercentage() {
    return this.localizedDecimalPipe.transform(this.progress);
  }

  get progressBarLeftStyle() {
    if (this.progress > 0.5) {
      return { transform: 'rotate(' + ((this.progress - 0.5) * 2) * 180 + 'deg)' };
    } else {
      return { transform: 'rotate(0deg)' };
    }
  }

  get progressBarRightValue() {
    if (this.progress < 0.5) {
      return { transform: 'rotate(' + (this.progress * 2) * 180 + 'deg)' };
    } else {
      return { transform: 'rotate(180deg)' };
    }
  }
}
