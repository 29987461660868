import { Component, Input } from '@angular/core';
import { ColumnActionInterface } from '../tables/sortable-table/column/column-action.interface';
import { ActionsButtonActionInterface } from './actions-button-action.interface';

@Component({
  selector: 'app-actions-button',
  templateUrl: './actions-button.component.html',
  styleUrls: ['./actions-button.component.css']
})
export class ActionsButtonComponent {

  @Input() title: string;
  @Input() actions: ColumnActionInterface[];
  @Input() element: any;
  @Input() top: false;

}
