import { mapCollection, sortByString } from '../../utils/collection.utils';
import { Client } from '../client';
import { Origin } from '../origin';
import { ClientPlanBuilder } from './client-plan.builder';
import { OriginBuilder } from './origin.builder';

export class ClientBuilder {
  static fromJson(json: any): Client {
    return json
      ? new Client(
        json.hash,
        json.cif,
        json.member ? json.member : false,
        json.memberType,
        json.name,
        json.firstSurname,
        json.email,
        json.telephone,
        json.bornDate,
        json.sex,
        json.lastPrescriptionStep,
        ClientPlanBuilder.fromList(json.clientPlans),
        OriginBuilder.fromJson(json.origin),
        json.city,
        json.profileImage,
        json.contact,
        json.hearService,
        json.hearServiceOthers,
      )
      : null;
  }

  static fromList(list: any): Client[] {
    return Array.isArray(list)
      ? sortByString('name', mapCollection(ClientBuilder.fromJson, list))
      : [];
  }
}
