import { Test } from './test';
import { Prescription } from './prescription';
export class TestPrescription {
  constructor(
    public hash?: string,
    public prescription?: Prescription,
    public test?: Test,
    public result1?: number,
    public result2?: number,
    public result3?: number,
    public result4?: number,
    public note?: string,
  ) { }

  clone() {
    return new TestPrescription(
      this.hash,
      this.prescription,
      this.test,
      this.result1,
      this.result2,
      this.result3,
      this.result4,
      this.note
    );
  }
}
