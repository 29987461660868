export class Origin {
  constructor(
    public hash?: string,
    public name?: string,
  ) { }

  clone(): Origin {
    return new Origin(
      this.hash,
      this.name
    );
  }
}
