import { Prescription } from './prescription';

export class PhysicalProfilePrescription {
  constructor(
    public hash?: string,
    public prescriptionHash?: string,
    public weight?: number,
    public height?: number,
    public bodyFat?: number,
    public visceralFat?: number,
    public muscleMass?: number,
    public metabolicAge?: number,
    public prescription?: Prescription
  ) { }

  clone() {
    return new PhysicalProfilePrescription(
      this.hash,
      this.prescriptionHash,
      this.weight,
      this.height,
      this.bodyFat,
      this.visceralFat,
      this.muscleMass,
      this.metabolicAge,
      this.prescription
    );
  }
}
