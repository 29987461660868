import { mapCollection, sortByString } from '../../utils/collection.utils';
import { Origin } from '../origin';

export class OriginBuilder {
  static fromJson(json: any): Origin {
    return json
      ? new Origin(
        json.hash,
        json.name
      )
      : null;
  }

  static fromList(list: any): Origin[] {
    return Array.isArray(list)
      ? sortByString('name', mapCollection(OriginBuilder.fromJson, list))
      : [];
  }
}
