import { Prescription } from 'src/app/core/models/prescription';
export class ChangePrescription {
  constructor(
    public hash?: string,
    public prescriptionHash?: string,
    public answer1?: string,
    public answer2?: string,
    public answer3?: string,
    public answer4?: string,
    public result?: string,
    public prescription?: Prescription
  ) { }

  clone() {
    return new ChangePrescription(
      this.hash,
      this.prescriptionHash,
      this.answer1,
      this.answer2,
      this.answer3,
      this.answer4,
      this.result,
      this.prescription
    );
  }
}
