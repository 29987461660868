import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BenefitBuilder } from '../../models/builder/benefit.builder';

@Injectable()
export class BenefitApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}benefits`;
  }

  getBenefits() {
    return this.httpClient.get(`${this.apiUrl}`).pipe(map(BenefitBuilder.fromList));
  }
}
