export class Pathology {
  constructor(
    public hash?: string,
    public name?: string,
  ) { }

  clone() {
    return new Pathology(
      this.hash,
      this.name
    );
  }
}
