import { mapCollection } from '../../utils/collection.utils';
import { parseNumber } from '../../utils/number.utils';
import { ClientPlan } from '../client-plan';
import { ClientPlanRoutineLevelBuilder } from './client-plan-routine-level.builder';

export class ClientPlanBuilder {
  static fromJson(json: any): ClientPlan {
    return json
      ? new ClientPlan(
        json.hash,
        parseNumber(json.startDate),
        parseNumber(json.endDate),
        ClientPlanRoutineLevelBuilder.fromMatrix(json.clientPlanRoutineLevels),
      )
      : null;
  }

  static fromList(list: any): ClientPlan[] {
    return Array.isArray(list)
      ? mapCollection(ClientPlanBuilder.fromJson, list)
      : [];
  }
}
