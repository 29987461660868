import { mapCollection } from '../../utils/collection.utils';
import { TestPrescription } from '../test-prescription';

export class TestPrescriptionBuilder {
  static fromJson(json: any): TestPrescription {
    return json
      ? new TestPrescription(
        json.hash,
        json.prescription,
        json.test,
        json.result1,
        json.result2,
        json.result3,
        json.result4,
        json.note
      )
      : null;
  }

  static fromList(list: any): TestPrescription[] {
    return Array.isArray(list)
      ? mapCollection(TestPrescriptionBuilder.fromJson, list)
      : [];
  }
}
